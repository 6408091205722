// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../../template/RenderPage';

// TempData
import Data from '../../mocks/pages/PageData/WideCard_data';

// Class
const WideCard = () => <div>{renderPage(Data)}</div>;

export default WideCard;
