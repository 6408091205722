// Import SlideShow Images
import nemid from '../../../assets/Images/image-nemid.png';
import Image from '../../../assets/Images/image-appstore-2.png';
import nemidMobile from '../../../assets/Images/image-nemid-mobile.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'WideCard Test Page',
  pageTemplate: 'Template2',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'WideCard',
            componentData: {
              id: 1,
              image: Image,
              imageMobile: nemidMobile,
              imageAltText: 'Nem ID',
              imageRight: true,
              text:
                '<h2>Log ind og se dine tal</h1>'
                + '<p class="intro">Den nemmeste måde at få overblik på er at logge ind med NemID. Her kan du se, hvor meget du får udbetalt, når du går på pension, hvis du bliver syg eller hvordan din familie er dækket, hvis du dør. Tallene er bruttotal, det vil sige før skat.</p><a href="test" class="linkBtn">test</a>',
              btnText: 'Log ind og få overblik',
              btnUrl: '/btnUrl1',
            },
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'WideCard',
      componentData: {
        id: 1,
        image: nemid,
        imageMobile: nemidMobile,
        imageAltText: 'Nem ID',
        imageRight: false,
        text:
          '<h2>Log ind og se dine tal</h1><p class="intro">Den nemmeste måde at få overblik på er at logge ind med NemID. Her kan du se, hvor meget du får udbetalt, når du går på pension, hvis du bliver syg eller hvordan din familie er dækket, hvis du dør. Tallene er bruttotal, det vil sige før skat.</p><a href="#" class="primaryBtn">test</a>',
        btnText: 'Log ind og få overblik',
        btnUrl: 'http://www.google.com',
      },
    },
  ],
};

export default Data;
